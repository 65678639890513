<template>
  <div class="timer">
    <div class="time-block" v-for="(digit, index) in timeDigits" :key="index">
      <span>{{ digit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalTimeInSeconds: 900,
      currentTimeInSeconds: 900,
      timerInterval: null
    };
  },
  computed: {
    timeDigits() {
      const minutes = Math.floor(this.currentTimeInSeconds / 60);
      const seconds = this.currentTimeInSeconds % 60;
      return [
        String(Math.floor(minutes / 10)),
        String(minutes % 10),
        ":",
        String(Math.floor(seconds / 10)),
        String(seconds % 10)
      ];
    }
  },
  methods: {
    startTimer() {
      this.currentTimeInSeconds = this.totalTimeInSeconds;
      this.timerInterval = setInterval(this.updateTimer, 1000);
    },
    updateTimer() {
      if (this.currentTimeInSeconds > 0) {
        this.currentTimeInSeconds--;
      } else {
        clearInterval(this.timerInterval);
        this.startTimer();
      }
    }
  },
  created() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  }
};
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.timer
  display: flex
  justify-content: center
  align-items: center
  gap: 5px
  .time-block
    width: 50px
    padding: 10px
    background-color: var(--accent-color)
    border-radius: 10px
    display: flex
    flex-direction: row
    justify-content: center
    gap: 5px
    span
      font-size: 2.5rem
      font-style: normal
      font-weight: 600
      color: white
    &:nth-child(3)
      background-color: unset
      padding-left: 5px
      padding-right: 5px
      width: 20px
      span
        color: var(--accent-color)
@media only screen and (max-width: $bp-pc)
  .timer
    .time-block
      width: 30px
      padding: 10px 0
      background-color: var(--accent-color)
      gap: 5px
      span
        font-size: 1.5rem
      &:nth-child(3)
        background-color: unset
        padding-left: 0
        padding-right: 0
        width: 6px
@media only screen and (max-width: $bp-tablet)
  .timer
    .time-block
      width: 25px
      padding: 10px 0
      background-color: var(--accent-color)
      gap: 5px
      span
        font-size: 1rem
      &:nth-child(3)
        background-color: unset
        padding-left: 0
        padding-right: 0
        width: 4px
</style>
